import React, { useEffect, useRef, useState } from "react";
import { Link, Navigate, Route, Routes } from "react-router-dom";
import CreateAccount from "./CreateAccount";
import SignIn from "./SignIn";
import { useUserContext } from "../context/UserProvider";
import { ClockIcon, EventAvailableIcon, MedalIcon } from "./util/Icons";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

const CelebrateCanada = () => {
  const { user, userRaces } = useUserContext();
  const [selected, setSelected] = useState(null);
  const [spotsLeft, setSpotsLeft] = useState("...");
  function hasRace(raceIds) {
    if (!userRaces) return false;
    for (let r in raceIds) {
      let raceId = raceIds[r];
      if (!!userRaces.get(raceId)) {
        return true;
      }
    }
    return false;
  }

  //Get spots left
  useEffect(() => {
    async function getSpotsLeft() {
      let spotsLeftDoc = await getDoc(doc(db, "options", "cccSpotsLeft"));
      if (spotsLeftDoc.data().spotsLeft) {
        setSpotsLeft(spotsLeftDoc.data().spotsLeft);
      }
    }
    getSpotsLeft();
  }, []);
  return (
    <Routes>
      <Route
        path="createaccount"
        element={
          <>
            {/* <source srcSet={process.env.PUBLIC_URL + `/img/${selectedRace.metadata.checkoutBanner}.png`} type="image/png" /> */}
            {/* <picture>
              <img
                className="w-full max-w-lg mb-4 mt-4 mx-auto text-center text-sm"
                src="https://mychallengecanada.ca/wp-content/uploads/2024/03/checkout-ccc-2024-1.png"
                alt="Promotional banner for celebrate canada"
                title="Celebrate Canada!"
              ></img>
            </picture> */}
            <CreateAccount
              checkoutBanner={
                "https://firebasestorage.googleapis.com/v0/b/bigtimerun-4c245.appspot.com/o/banners%2Fcheckout%2Fcheckout-ccc-2025.png?alt=media&token=fd690d29-e7d8-4d1e-acfa-b0a1e0c4ad8c"
              }
            />
          </>
        }
      />
      <Route
        path="signin"
        element={
          <>
            {/* <picture>
              <img
                className="w-full max-w-lg mb-4 mt-4 mx-auto text-center text-sm"
                src="https://mychallengecanada.ca/wp-content/uploads/2024/03/checkout-ccc-2024-1.png"
                alt="Promotional banner for celebrate canada"
                title="Celebrate Canada!"
              ></img>
            </picture> */}
            <SignIn
              checkoutBanner={
                "https://firebasestorage.googleapis.com/v0/b/bigtimerun-4c245.appspot.com/o/banners%2Fcheckout%2Fcheckout-ccc-2025.png?alt=media&token=fd690d29-e7d8-4d1e-acfa-b0a1e0c4ad8c"
              }
            />
          </>
        }
      />
      <Route
        path={"/"}
        element={
          !user ? (
            <Navigate to={`createaccount${window.location.search}`} />
          ) : (
            <div className="top-0 left-0 w-full h-full flex flex-col pb-3">
              <div
                className="top-0 left-0 w-full h-full absolute"
                style={{
                  backgroundImage:
                    'url("https://mychallengecanada.ca/wp-content/uploads/2023/03/the-mountains-are-calling-and-i-must-go-john-muir_t20_8kVZLj-scaled-1.jpg")',
                  backgroundSize: "cover",
                }}
              ></div>
              <div className="absolute left-0 top-0 w-full h-full bg-black opacity-80 z-0"></div>
              <div className="!text-white z-1 text-center mt-8 mx-12">
                <h1 className="text-3xl mb-3 font-bold">Build Your Canada Medal Collection</h1>
                <p className="mt-0 mb-3 text-xl font-semibold italic">Select Your Challenge & Start Your Journey!</p>
                <p className="mt-0 mb-2 rounded-2xl bg-[#EE4444] mx-auto w-fit px-2 py-1 font-semibold">
                  ONLY <span className="underline">{spotsLeft}</span> SPOTS LEFT
                </p>
              </div>
              <div className=" w-full h-full max-w-3xl mx-auto grid items-start z-1 sm:grid-cols-6 grid-cols-3 pt-4">
                <Medal
                  to={"/register/celebratecanada-c"}
                  src={[
                    "https://mychallengecanada.ca/wp-content/uploads/2024/02/1-min-1-740x1024.png",
                    "https://mychallengecanada.ca/wp-content/uploads/2024/02/CCC-Medals-MedalHub-min-740x1024.png",
                  ]}
                  owned={hasRace(["price_1OpEITKSwTC8CjLXEIlF6JOi", "price_1Msu9vKSwTC8CjLXQ5UbfxQw"])}
                  alt="Medal C1"
                  selected={selected}
                  setSelected={setSelected}
                  text={[
                    'Medal "C" Details',
                    "156km Challenge. Finish at your own pace.",
                    "Early Bird - $79 (until June 30th)",
                    "Regular Price - $99",
                  ]}
                />
                <Medal
                  to={"/register/celebratecanada-a1"}
                  src={[
                    "https://mychallengecanada.ca/wp-content/uploads/2024/02/3-min-740x1024.png",
                    "https://mychallengecanada.ca/wp-content/uploads/2024/02/A-Check-min-740x1024.png",
                  ]}
                  owned={hasRace(["price_1OoqVWKSwTC8CjLXof1wIqO9", "price_1OpazdKSwTC8CjLX1E2Nk0JF"])}
                  alt="Medal A2"
                  selected={selected}
                  setSelected={setSelected}
                  text={[
                    'Medal "A" Details',
                    "157km Challenge. Finish at your own pace.",
                    "Early Bird - $79 (until June 30th)",
                    "Regular Price - $99",
                  ]}
                />

                {/* <div className="w-full">
                  <div className="w-full">
                    <img src="https://mychallengecanada.ca/wp-content/uploads/2024/02/4-N-740x1024.png" alt="Medal N3" />
                  </div>
                </div> */}
                <Medal
                  to={"/register/celebratecanada-n"}
                  src={["/img/medals/medal-N.png", "/img/medals/medal-N-check.png"]}
                  owned={hasRace(["price_1QyaVOKSwTC8CjLXGaMUMiWQ", "price_1QylUaKSwTC8CjLX9jjrJa0n"])}
                  alt="Medal N"
                  selected={selected}
                  setSelected={setSelected}
                  text={[
                    'Medal "N" Details',
                    "158km Challenge. Finish at your own pace.",
                    "Early Bird - $79 (until June 30th)",
                    "Regular Price - $99",
                  ]}
                />
                <div className="w-full">
                  <img src="https://mychallengecanada.ca/wp-content/uploads/2024/02/5-A-740x1024.png" alt="Medal A4" />
                </div>
                <div className="w-full">
                  <img src="https://mychallengecanada.ca/wp-content/uploads/2024/02/6-D-740x1024.png" alt="Medal D5" />
                </div>
                <div className="w-full">
                  <img src="https://mychallengecanada.ca/wp-content/uploads/2024/02/7-3A-740x1024.png" alt="Medal A6" />
                </div>
              </div>
            </div>
          )
        }
      ></Route>
    </Routes>
  );
};

function Medal({ to = "", src = ["", ""], alt = "", owned = false, selected, setSelected, text = [] }) {
  to = to + window.location.search;
  const isSelected = selected === alt;
  const infoDivRef = useRef();
  let left = "-50%"; //infoDivRef.current.clientWidth * 0.5 * -1 + "px";
  if (infoDivRef.current) {
    left = Math.min(infoDivRef.current.clientWidth * 1, infoDivRef.current.offsetLeft - 16);
    let diff = window.innerWidth - (infoDivRef.current.offsetLeft - infoDivRef.current.clientWidth * 1 + infoDivRef.current.clientWidth * 3);
    if (diff < 0) {
      left -= diff - 16;
    }
    left *= -1;
  }

  return (
    <div ref={infoDivRef} className="no-underline cursor-pointer w-full" style={{}}>
      <div className="pb-2" style={{ borderBottom: isSelected ? "2px solid red" : "" }}>
        <img
          onClick={() => {
            if (isSelected) {
              setSelected(null);
            } else {
              setSelected(alt);
              // window.scrollTo({ top: infoDivRef.current?.offsetTop, behavior: "smooth" });
              setTimeout(() => {
                infoDivRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
              }, 10);
            }
          }}
          src={owned ? src[1] : src[0]}
          alt={alt}
          style={{
            filter: to === "" ? "grayscale(1)" : "",
            // borderBottom: isSelected ? "2px solid red" : "",
            // translate: isSelected ? "0 -12px" : "",
            transition: "transform 100ms ease-in-out",
            // transform: isSelected ? "translateY(-11px)" : "",
          }}
        />
        {!owned && <p className="mt-0 mb-0 rounded-2xl bg-[#EE4444] mx-auto w-fit px-2 text-xs font-medium text-white">START</p>}
      </div>
      <div
        // ref={infoDivRef}
        className="relative top-2 pb-3 w-[350%] max-w-[100vw] rounded-sm text-white bg-[#192130]"
        style={{
          border: "1px solid #FFF4",
          borderTop: "7px solid red",
          borderRadius: "5px",
          transition: isSelected ? "transform 200ms ease-in-out, height 200ms ease-in-out" : "",
          transformOrigin: "top",
          // display: isSelected ? "revert" : "none",
          left: left,
          transform: isSelected ? "scaleY(1)" : "scaleY(0)",
          height: isSelected ? "100%" : "0",
        }}
      >
        <div className="font-semibold mt-3">
          <div className="flex items-center mx-3">
            <MedalIcon size={20} color={"#EE4444"}></MedalIcon>
            <p className="mb-0 ml-3 font-bold text-lg">{text[0]}</p>
          </div>
          <div className="flex items-center mx-3 mt-3">
            <ClockIcon size={20}></ClockIcon>
            <p className="mb-0 ml-3">{text[1]}</p>
          </div>
          <div className="flex items-center mx-3 mt-2">
            <EventAvailableIcon size={20}></EventAvailableIcon>
            <p className="mb-0 ml-3">{text[2]}</p>
          </div>
          <p className="m-0 line-through mx-3 mb-3 mt-2 text-[#69727F]">{text[3]}</p>
        </div>
        <Link to={to} className="bg-[red] no-underline mx-5 text-center block rounded-md font-bold text-white px-2 py-2">
          {"Register Now >"}
        </Link>
      </div>
    </div>
  );
}

export default CelebrateCanada;
